import { useCallback } from 'react';

import { useGetPreviewPanelsContext } from './context';
import type { OpenParams } from './types';

export const usePreviewPanelsActions = () => {
	const context = useGetPreviewPanelsContext();
	const dispatch = context?.dispatch;

	const setPanelWidth = useCallback(
		(width: number) => {
			dispatch?.({ type: 'SET_PANEL_WIDTH', width });
		},
		[dispatch],
	);

	const close = useCallback(
		(onClose?: () => void) => {
			dispatch?.({ type: 'CLOSE', onClose });
		},
		[dispatch],
	);

	const open = useCallback(
		(params: OpenParams) => {
			dispatch?.({ type: 'OPEN', params });
		},
		[dispatch],
	);

	return { setPanelWidth, close, open };
};
