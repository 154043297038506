import type { PreviewPanelsApiState } from './types';

export const initialState: PreviewPanelsApiState = {
	panel: {
		id: '',
		data: undefined,
		content: undefined,
	},
	width: 400,
	closePanelSlot: () => {},
	openPanelSlot: () => {},
	onOpen: () => {},
};
