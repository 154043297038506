import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContainer = styled.div({
	alignItems: 'center',
	boxSizing: 'border-box',
	display: 'flex',
	padding: `30px ${token('space.100')} 30px 18px`,
	width: '214px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceIconSkeleton = styled.div({
	width: '24px',
	height: '24px',
	borderRadius: '5px',
	backgroundColor: token('color.skeleton.subtle'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '18px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceTitleSkeleton = styled.div({
	width: '150px',
	height: '16px',
	borderRadius: '4px',
	backgroundColor: token('color.skeleton.subtle'),
});

export function SpaceHeaderSkeleton() {
	return (
		<SkeletonContainer data-testid="space-header-skeleton">
			<SpaceIconSkeleton />
			<SpaceTitleSkeleton />
		</SkeletonContainer>
	);
}
