import type { FC } from 'react';
import React, { memo } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { SpaceHeaderSkeleton } from './SpaceHeaderSkeleton';

type LoadingProps = {
	header?: boolean;
	loader?: boolean;
	isloadingState?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	backgroundColor: token('elevation.surface'),
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContainer = styled.div<LoadingProps>({
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonLine = styled.div<LoadingProps>({
	alignItems: 'center',
	boxSizing: 'border-box',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	flexDirection: 'row !important',
	height: '40px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: (props: LoadingProps) => (props.isloadingState ? '18px' : '10px'),
	paddingRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Circle = styled.div({
	backgroundColor: token('color.skeleton.subtle'),
	borderRadius: '50%',
	flexShrink: 0,
	height: '24px',
	marginRight: token('space.200'),
	width: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Line = styled.div({
	borderRadius: '4px',
	height: '16px',
	width: '120px',
	backgroundColor: token('color.skeleton.subtle'),
});

export const ContainerSkeleton: FC<LoadingProps> = memo(({ header = true, loader }) => (
	<Wrapper>
		<SkeletonContainer data-testid="sidebar-skeleton">
			{loader && header ? <SpaceHeaderSkeleton /> : null}
			<SkeletonLine isloadingState={loader}>
				<Circle />
				<Line />
			</SkeletonLine>
			<SkeletonLine isloadingState={loader}>
				<Circle />
				<Line />
			</SkeletonLine>
			<SkeletonLine isloadingState={loader}>
				<Circle />
				<Line />
			</SkeletonLine>
			<SkeletonLine isloadingState={loader}>
				<Circle />
				<Line />
			</SkeletonLine>
			<SkeletonLine isloadingState={loader}>
				<Circle />
				<Line />
			</SkeletonLine>
		</SkeletonContainer>
	</Wrapper>
));
