import type React from 'react';
import { type FC, useCallback, useMemo } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { createStore, createHook, createStateHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';

import { useGetPreviewPanelsActions } from '@atlassian/preview-panels-api';

import { fg } from '@confluence/feature-gating';

import type { PanelName } from './PanelName';

/**
 * IF YOU MAKE CHANGES to MIN_PANEL_WIDTH or the defaultPanelWidth calculation, please ensure you update the width
 * of the 'ObjectSidebarPushPlaceholder' accordingly. Otherwise, the content may not be pushed over the correct amount
 * when the ObjectSidebarPanel is open.
 */
export const CONTENT_RIGHT_PADDING = 40;
// We will always reset the panel to the default size when we close it
export const MIN_PANEL_WIDTH = 400;
export const defaultPanelWidth = Math.max(MIN_PANEL_WIDTH, 368) + CONTENT_RIGHT_PADDING;

// Below type is copied to;
// - platform/packages/editor/editor-plugin-context-panel/src/types/object-siderbar-types.ts
// - platform/packages/insights-ai-platform/ai-smart-button/src/ui/ai-smart-button/object-sidebar-types.ts
export type ObjectSidebarControlState = 'shown' | 'hidden';
// Below type is copied to;
// - platform/packages/editor/editor-plugin-context-panel/src/types/object-siderbar-types.ts
// - platform/packages/insights-ai-platform/ai-smart-button/src/ui/ai-smart-button/object-sidebar-types.ts
export type ObjectSidebarBehavior = 'push' | 'cover';
// Below type is copied to;
// - platform/packages/editor/editor-plugin-context-panel/src/types/object-siderbar-types.ts
// - platform/packages/insights-ai-platform/ai-smart-button/src/ui/ai-smart-button/object-sidebar-types.ts
export type ObjectSidebarPanelCloseOptions = {
	canClosePanel?: () => boolean; // Returns true if panel can be closed
	onPanelClose?: () => void;
};

export type ObjectSidebarControlExpandCollapsedState = 'expanded' | 'collapsed';

type PanelElement = FC | (() => React.ReactElement<any, any> | null);

// Below type is copied to;
// - platform/packages/editor/editor-plugin-context-panel/src/types/object-siderbar-types.ts
// - platform/packages/insights-ai-platform/ai-smart-button/src/ui/ai-smart-button/object-sidebar-types.ts
export type ObjectSidebarPanel = {
	id: PanelName | string;
	headerComponentElements: {
		HeaderIcon?: PanelElement;
		headerLabel?: MessageDescriptor;
		HeaderAfterIconElement?: PanelElement;
		HeaderRightAlignedElement?: PanelElement;
		HeaderBeforeIconElement?: PanelElement;
		headerStyles?: {
			setGrayBackground?: boolean;
		};
	};
	BodyComponent: PanelElement;
	FooterComponent?: PanelElement;
	closeOptions?: ObjectSidebarPanelCloseOptions;
};

export type ObjectSidebarState = {
	isPanelScrolled: boolean;
	isObjectSidebarShown: boolean;
	behavior: ObjectSidebarBehavior;
	panelWidth: number;
	panel?: ObjectSidebarPanel;
	sidebarControlState: ObjectSidebarControlState;
	sidebarControlExpandCollapsedState: ObjectSidebarControlExpandCollapsedState;
};

export const initialState: ObjectSidebarState = {
	isPanelScrolled: false,
	isObjectSidebarShown: false,
	behavior: 'push',
	panelWidth: defaultPanelWidth,
	panel: undefined,
	// set to shown only when hydration is off coz of different server and client html output
	// controlled by useEffect instead
	// this can be cleaned up when we are showing the control sidebar on server
	sidebarControlState:
		window.__SSR_RENDERED__ && !(window as any)?.__HYDRATABLE__ ? 'shown' : 'hidden',
	sidebarControlExpandCollapsedState:
		window.__SSR_RENDERED__ && !(window as any)?.__HYDRATABLE__ ? 'expanded' : 'collapsed',
};

export const actions = {
	setIsPanelScrolled:
		(isPanelScrolled: boolean) =>
		({ setState }: StoreActionApi<ObjectSidebarState>) => {
			setState({ isPanelScrolled });
		},
	setSidebarControlState:
		(sidebarControlState: ObjectSidebarControlState) =>
		({ setState }: StoreActionApi<ObjectSidebarState>) => {
			setState({ sidebarControlState });
		},
	setSidebarControlExpandCollapsedState:
		(sidebarControlExpandCollapsedState: ObjectSidebarControlExpandCollapsedState) =>
		({ setState }: StoreActionApi<ObjectSidebarState>) => {
			setState({ sidebarControlExpandCollapsedState });
		},
	toggleObjectSidebarControl:
		() =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { sidebarControlExpandCollapsedState } = getState();
			if (sidebarControlExpandCollapsedState === 'collapsed') {
				setState({ sidebarControlExpandCollapsedState: 'expanded' });
			} else {
				setState({ sidebarControlExpandCollapsedState: 'collapsed' });
			}
		},
	showObjectSidebar:
		(panel: ObjectSidebarPanel, behavior?: ObjectSidebarBehavior, panelWidth?: number) =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const {
				isObjectSidebarShown,
				panel: currentPanel,
				behavior: currentBehavior,
				panelWidth: currentPanelWidth,
			} = getState();

			// Check if panel is open
			if (isObjectSidebarShown) {
				// If it's the same panel do nothing
				if (currentPanel?.id === panel.id) {
					return;
				}

				// Otherwise change it
				setState({
					panel,
					behavior: behavior ?? currentBehavior,
					panelWidth: panelWidth ?? currentPanelWidth,
					isPanelScrolled: false,
				});
			}

			// Set the panel to be open
			setState({
				isObjectSidebarShown: true,
				panel,
				behavior: behavior ?? currentBehavior,
				panelWidth: panelWidth ?? currentPanelWidth,
				sidebarControlState: 'hidden',
				isPanelScrolled: false,
			});

			// Close the global panel
			window.dispatchEvent(new Event('closeGlobalSidebar'));
		},
	hideObjectSidebar:
		() =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { isObjectSidebarShown, panel } = getState();

			// If we're not showing a panel, don't do anything
			if (!isObjectSidebarShown || !panel) {
				return;
			}

			// If the user has provided closeOptions check them
			if (panel.closeOptions) {
				const { canClosePanel, onPanelClose } = panel.closeOptions;

				// If the panel closing check returns false, do nothing
				if (canClosePanel && !canClosePanel()) {
					return;
				}

				// Call the onClose callback
				onPanelClose && onPanelClose();
			}

			// Close the panel
			setState({
				isObjectSidebarShown: false,
				panel: undefined,
				panelWidth: defaultPanelWidth,
				sidebarControlState: 'shown',
				isPanelScrolled: false,
			});
		},
	hideObjectSidebarByPanelId:
		(id: ObjectSidebarPanel['id']) =>
		({ getState, dispatch }: StoreActionApi<ObjectSidebarState>) => {
			const { panel } = getState();

			if (panel?.id === id) {
				dispatch(actions.hideObjectSidebar());
			}
		},
	setPanelWidth:
		(newPanelWidth: number) =>
		({ setState }: StoreActionApi<ObjectSidebarState>) => {
			setState({ panelWidth: Math.max(newPanelWidth, MIN_PANEL_WIDTH) + CONTENT_RIGHT_PADDING });
		},
	changePanelBehavior:
		(newBehavior: ObjectSidebarBehavior) =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { panelWidth } = getState();

			// When we set the new state, if it's set to cover, we no longer have a
			// component that will size the panel accordingly so we just use the default
			setState({
				behavior: newBehavior,
				panelWidth: newBehavior === 'cover' ? defaultPanelWidth : panelWidth,
			});
		},
	// TODO: Do we need to specifically change Header, Body, and Footer components individually?
	changePanel:
		(newPanel: ObjectSidebarPanel, newBehavior?: ObjectSidebarBehavior, newPanelWidth?: number) =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { isObjectSidebarShown, panel, behavior, panelWidth } = getState();

			// Only change panels if the panel is open
			if (isObjectSidebarShown && panel) {
				// If it's the same panel do nothing
				if (panel.id === newPanel.id) {
					return;
				}

				// If the user has provided closeOptions check them
				if (panel.closeOptions) {
					const { canClosePanel, onPanelClose } = panel.closeOptions;

					// If the panel closing check returns false, do nothing
					if (canClosePanel && !canClosePanel()) {
						return;
					}

					// Call the onClose callback
					onPanelClose && onPanelClose();
				}

				setState({
					panel: newPanel,
					behavior: newBehavior ?? behavior,
					panelWidth: newPanelWidth ?? panelWidth,
					isPanelScrolled: false,
				});
			}

			// Otherwise do nothing
		},
	changeHeaderElements:
		(
			id: ObjectSidebarPanel['id'],
			headerComponentElements: ObjectSidebarPanel['headerComponentElements'],
		) =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { panel } = getState();
			if (panel?.id === id) {
				setState({
					panel: {
						...panel,
						headerComponentElements,
					},
				});
			}
		},
};

export type ObjectSidebarActions = typeof actions;

export const ObjectSidebarStore = createStore({
	initialState,
	actions,
	name: 'ObjectSidebarStore',
});

type ObjectSidebarActionReturnType = {
	[K in keyof ObjectSidebarActions]: (
		...args: Parameters<ObjectSidebarActions[K]>
	) => ReturnType<ReturnType<ObjectSidebarActions[K]>>;
};

const useObjectSidebarInternal = createHook(ObjectSidebarStore);

export const useObjectSidebar: () => [ObjectSidebarState, ObjectSidebarActionReturnType] = () => {
	const [internalState, internalActions] = useObjectSidebarInternal();
	const { close } = useGetPreviewPanelsActions();

	const showObjectSidebarWrapper = useCallback(
		(panel: ObjectSidebarPanel, behavior?: ObjectSidebarBehavior, panelWidth?: number) => {
			internalActions.showObjectSidebar(panel, behavior, panelWidth);
			void close();
		},
		[internalActions, close],
	);

	const showObjectSidebar = useMemo(
		() =>
			fg('confluence_preview_panels')
				? showObjectSidebarWrapper
				: internalActions.showObjectSidebar,
		[showObjectSidebarWrapper, internalActions.showObjectSidebar],
	);

	const memoizedActions = useMemo(
		() => ({
			...internalActions,
			showObjectSidebar,
		}),
		[internalActions, showObjectSidebar],
	);

	return useMemo(() => [internalState, memoizedActions], [internalState, memoizedActions]);
};

export const useObjectSidebarState = createStateHook(ObjectSidebarStore);

export const useObjectSidebarActions = () => {
	const [, internalActions] = useObjectSidebar();
	return internalActions;
};
