import React, { createContext, type Dispatch, type ReactNode, useContext, useReducer } from 'react';

import { initialState } from './constants';
import type { PreviewPanelsAction, PreviewPanelsApiState } from './types';

export const PreviewPanelsContext = createContext<
	| {
			state: PreviewPanelsApiState;
			dispatch: Dispatch<PreviewPanelsAction>;
	  }
	| undefined
>(undefined);

export const previewPanelsReducer = (
	state: PreviewPanelsApiState,
	action: PreviewPanelsAction,
): PreviewPanelsApiState => {
	switch (action.type) {
		case 'SET_PANEL_WIDTH':
			return { ...state, width: action.width };
		case 'CLOSE':
			action.onClose?.();
			state.closePanelSlot();
			return initialState;
		case 'OPEN':
			const { params } = action;
			if (state.panel.id === params.panel.id) {
				return state;
			}
			params.closePanelSlot();
			params.openPanelSlot();
			params.onOpen();
			return {
				...state,
				panel: params.panel,
				width: params.width || state.width,
				closePanelSlot: params.closePanelSlot,
				openPanelSlot: params.openPanelSlot,
				onOpen: params.onOpen,
			};
		default:
			return state;
	}
};

export const PreviewPanelsProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(previewPanelsReducer, initialState);
	return (
		<PreviewPanelsContext.Provider value={{ state, dispatch }}>
			{children}
		</PreviewPanelsContext.Provider>
	);
};

// Hook to use the context
export const useGetPreviewPanelsContext = () => {
	const context = useContext(PreviewPanelsContext);
	return context;
};

// Context selectors
export const useIsPreviewPanelOpen = (panelId?: string): boolean => {
	const context = useGetPreviewPanelsContext();
	return panelId ? context?.state.panel.id === panelId : !!context?.state.panel.id;
};
export const useGetCurrentPreviewPanel = () => {
	const context = useGetPreviewPanelsContext();
	return context?.state.panel ?? initialState.panel;
};
